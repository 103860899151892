//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import autoLockStatus from '@/dictionaries/autoLockStatus';
import ProfileDocuments from '@/components/Profile/ProfileDocuments';
var steps = {
  notify: 'notify',
  uploadDocuments: 'uploadDocuments',
  verification: 'verification'
};
export default {
  components: {
    'app-profile-documents': ProfileDocuments
  },
  data: function data() {
    return {
      autoLockStatus: autoLockStatus,
      steps: steps,
      step: steps.notify
    };
  },
  computed: {
    autoLock: function autoLock() {
      return this.$store.getters['player/autoLock'];
    },
    thumbStyle: function thumbStyle() {
      return {
        right: '-105px',
        borderRadius: '2px',
        backgroundColor: '#fff',
        width: '2px',
        opacity: 1,
        zIndex: 4
      };
    },
    barStyle: function barStyle() {
      return {
        right: '-105px',
        borderRadius: '4px',
        backgroundColor: '#fff',
        width: '1px',
        opacity: 0.1,
        zIndex: 4
      };
    }
  },
  methods: {
    setStep: function setStep(value) {
      this.step = value;
    },
    openSupport: function openSupport() {
      this.$store.$support.open();
      this.close();
    },
    mailTo: function mailTo() {
      window.location.href = 'mailto:support@piastercreek.com';
    },
    close: function close() {
      this.$store.dispatch('modals/setAutoLockModal', {
        isOpen: false
      });
    },
    openSupportFromVerification: function openSupportFromVerification() {
      this.$store.$support.open();
      this.closeFromVerification();
    },
    closeFromVerification: function closeFromVerification() {
      this.close();
      this.$store.dispatch('player/setAutoLock', autoLockStatus.waiting);
    }
  }
};